import React from 'react';
import './Pillars.scss';
import Pillar from './Pillar/Pillar';
import { graphql, useStaticQuery } from 'gatsby';
/**
 * @namespace Pillars
 * @function Pillars
 * @author Trevor Cash
 * @since 11/13/21
 * @version 1.0.0
 * @component
 */
export default function Pillars() {
  const data = useStaticQuery(graphql`
    query pillarsQuery {
      allSanityHomePage {
        nodes {
          worship {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
          community {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
          serve {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
          connect {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
        }
      }
    }
  `);
  const images = data?.allSanityHomePage?.nodes?.[0];

  return (
    <div className='Pillars-Container'>
      <h2>Connecting Church and Community</h2>
      <div className='Pillars'>
        <Pillar
          to='/worship'
          image={images?.worship?.asset?.url}
          hotspot={{
            x: images?.worship?.hotspot?.x,
            y: images?.worship?.hotspot?.y,
          }}
          title='Worship'
          color='#FF5733'
        />
        <Pillar
          to='/community'
          image={images?.community?.asset?.url}
          hotspot={{
            x: images?.community?.hotspot?.x,
            y: images?.community?.hotspot?.y,
          }}
          title='Community'
          color='#80FF33'
        />
        <Pillar
          to='/serve'
          image={images?.serve?.asset?.url}
          hotspot={{
            x: images?.serve?.hotspot?.x,
            y: images?.serve?.hotspot?.y,
          }}
          title='Serve'
          color='#33CEFF'
        />
        <Pillar
          to='/contact'
          image={images?.connect?.asset?.url}
          hotspot={{
            x: images?.connect?.hotspot?.x,
            y: images?.connect?.hotspot?.y,
          }}
          title='Connect'
          color='#D133FF'
        />
      </div>
    </div>
  );
}
