import React from 'react';
import './Home.scss';
import homeVideo from '../images/first-church-west-jefferson-ohio8.mp4';
// import Banner from '../../images/Banner.webp';
import Pillars from '../Components/Pillars/Pillars';
import Link from '../Components/Link/Link';
import Layout from '../Components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';

/**
 * @namespace Home
 * @function Home
 * @author Trevor Cash
 * @since 10/02/21
 * @version 1.0.0
 * @component
 */
export default function Home() {
  const data = useStaticQuery(graphql`
    query homeQuery {
      allSanityHome {
        nodes {
          currentSeries {
            slug {
              current
            }
            title
            _rawSummary
            banner {
              alt
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
      allSanityHomePage {
        nodes {
          title
          video {
            asset {
              url
            }
          }
          livestream {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
          inperson {
            hotspot {
              y
              x
            }
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const current = data?.allSanityHome?.nodes?.[0];
  const currentSeries = current?.currentSeries;
  const home = data?.allSanityHomePage?.nodes?.[0];
  console.log(currentSeries?.slug?.current);
  return (
    <Layout
      className='Home-Container'
      title='First Baptist Church of West Jefferson, Ohio'
      description='The website for First Baptist Church West Jefferson, OH (FBCWJ). Ministry, Missions, Services, and Opportunities to Worship and Serve can be found here.'
    >
      {currentSeries && (
        <section className='Series'>
          <div className='Series-Image'>
            {/* {current?.onImage && (
              <div className={'Series-Image-Banner ' + current?.position}>
                {current?.onImage}
              </div>
            )} */}
            <img
              src={currentSeries?.banner?.image?.asset?.url}
              alt={currentSeries?.banner?.alt}
            />
          </div>
          {currentSeries?.title && (
            <h2>Current Series: {currentSeries?.title}</h2>
          )}
          {currentSeries?._rawSummary && (
            <PortableText value={currentSeries?._rawSummary} />
          )}
          <Link className='Series-Link' to={'/sermons/recent'}>
            Watch our latest message
          </Link>
          <Link className='Series-Link' to={'/' + currentSeries?.slug?.current}>
            Read more about this series
          </Link>
        </section>
      )}
      <section className='Welcome'>
        <h2>{home?.title}</h2>
        <video src={homeVideo} controls />
      </section>
      <section className='Worship'>
        <h2>Join us for Worship</h2>
        <div className='TwoColumns'>
          <div className='Coulumn'>
            <img
              src={home?.inperson?.asset?.url}
              alt='In-Person Worship'
              loading='lazy'
            />
            <h3>In-Person Worship</h3>
            <Link to='/worship'>Details Here</Link>
          </div>
          <div className='Coulumn'>
            <img
              src={home?.livestream?.asset?.url}
              alt='Live Stream Worship'
              loading='lazy'
            />
            <h3>Live Stream Worship</h3>
            <Link to='/sermons/live'>Details Here</Link>
          </div>
        </div>
      </section>
      <section className='Areas'>
        <Pillars />
      </section>
    </Layout>
  );
}
