import React from 'react';
import './Pillar.scss';
import { Link } from 'gatsby';
// import {Lin}
/**
 * @namespace Pillar
 * @function Pillar
 * @author Trevor Cash
 * @since 11/13/21
 * @version 1.0.0
 * @component
 */
export default function Pillar({
  image,
  hotspot,
  title,
  description,
  color,
  to,
}) {
  return (
    <Link className='Pillar-Container' to={to}>
      <img
        className='Pillar-Image'
        src={image}
        alt={title}
        style={{ objectPosition: `${hotspot?.x * 100}% ${hotspot?.y * 100}%` }}
        loading='lazy'
      />
      <div className='Pillar-Text' style={{ backgroundColor: color + 'ee' }}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </Link>
  );
}
